import React, { useState, useEffect } from "react"
import styles from "./instaFeed.module.scss"
import instagramIcon from "../../images/common/instagram_white.svg"
import axios from 'axios'

const InstaFeed = ({ translations }) => {
  const [instaPosts, setInstaPosts] = useState([])

  const fetchPosts = async () => {
    const data = (
      await axios.get(
        `https://graph.facebook.com/v7.0/17841408123292978/media?fields=media_url,thumbnail_url,caption,media_type,timestamp,permalink&limit=9&access_token=EAADnl4w17Q8BAOLR4Oxfdsad3pvE06PWNcRvIgZAOO3ZBmZCcZC86lQMZCPo6jNYsP4y5Hiqntzh7JM97K9QRLcDG4VehqOI7eGpGnRrmlhZBgvxz8ztyoz7kItjAn2xZASBMFyRKIoq7eJzvUTgvZCPqbpAWbG8wgVaVomt2HK9eVbWnkM0Un295BMKRdUCH51dm0QRtjt6hOiQxHMmsUcvEW3Ib7YBhg7X5zFP3dzhkAZDZD`
      )
    ).data.data
    setInstaPosts(data)
  }

  useEffect(() => {
    fetchPosts()
  }, [])

  return (
    <div className={styles.instaFeed}>
      <h3>{translations.blog.xps_instagram}</h3>
      <div className={styles.instaList}>
        {instaPosts.map((p,i) => (
          <a key={`insta-${i}`} href={p.permalink} target="_blank">
            <img alt={`Instagram post ${i} preview`} src={p.thumbnail_url || p.media_url} />
          </a>
        ))}
      </div>
      <a
        className={styles.followButton}
        href="https://instagram.com/xpsnetwork"
        target="_blank"
      >
        <img src={instagramIcon} alt="instagram icon" />{" "}
        {translations.blog.follow_instagram}
      </a>
    </div>
  )
}

export default InstaFeed
