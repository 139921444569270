import React from "react"
import styles from "./relatedArticles.module.scss"
import ArticlesTable from "./articlesTable"

const RelatedArticles = ({ articles, translations }) => {
  return (
    <div className={styles.relatedArticles}>
      <h2>{translations.blog.related_articles}</h2>
      {articles?.length && <ArticlesTable articles={articles} translations={translations}/>}
    </div>
  )
}

export default RelatedArticles
