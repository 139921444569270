import React, { useEffect, useMemo, useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import NewHeader from "../components/blog/header"
import Landing from "../components/blog/postLanding"
import Footer from "../components/footer"
import Subscribe from "../components/blog/subscribe"
import RelatedArticles from "../components/blog/relatedArticles"
import styles from "./post.module.scss"
import "../styles/global.css"
import youtubeIcon from "../images/common/youtube_white.svg"
import quotesIcon from "../images/blog/quotes.svg"
import InstaFeed from "../components/blog/instaFeed"
import Search from "../components/blog/search"

const Post = ({ data, pageContext }) => {
  const _ = data.texts._
  const relatedPosts = useMemo(() => data.relatedPosts.nodes, [data])
  const [isSearchOpen, setIsSearchOpen] = useState(false)

  useEffect(() => {
    const videoWrapperEls = document.querySelectorAll(".epyt-video-wrapper")
    if (!document.querySelector(".epyt-video-wrapper > .youtube-link")) {
      const youtubeLinkEl = document.createElement("a")
      youtubeLinkEl.innerHTML = `<img class="youtube-icon" src="${youtubeIcon}"/>${_.blog.subscribe_youtube}`
      youtubeLinkEl.className = "youtube-link"
      youtubeLinkEl.href =
        "https://www.youtube.com/c/XPSNetwork?sub_confirmation=1"
      videoWrapperEls.forEach((v) => {
        v.appendChild(youtubeLinkEl.cloneNode(true))
      })
    }

    const blockquoteEls = document.querySelectorAll("blockquote")
    if (!document.querySelector("blockquote > img")) {
      blockquoteEls.forEach((b) => {
        const blockquoteText = b.innerHTML
        b.innerHTML = `<img src="${quotesIcon}">${blockquoteText}<img src="${quotesIcon}">`
      })
    }
    const imgEls = document.querySelectorAll("img")
    imgEls.forEach((i) => {
      i.removeAttribute("height")
    })
  }, [])
  return (
    <Layout
      header={
        <NewHeader
          fixed
          translations={_}
          languageCode={pageContext.data.lang}
          search={() => setIsSearchOpen(true)}
        />
      }
      translations={_}
      languageCode={pageContext.data.lang}
      addBlogPostContentType
      customMeta={{
        title: pageContext.data.title,
        description: pageContext.data.excerpt
          .split(/^<p>|<\/p>|&#.*;/)
          .join(""),
        image: pageContext.data.image,
      }}
    >
      <Landing
        title={pageContext.data.title}
        date={pageContext.data.date}
        image={pageContext.data.image}
        categories={pageContext.data.categories}
        readingTime={pageContext.data.readingTime}
        translations={_}
      />
      <div
        dangerouslySetInnerHTML={{
          __html: pageContext.data.content,
        }}
        className={styles.postContent}
      />
      <InstaFeed translations={_} />
      <RelatedArticles articles={relatedPosts} translations={_} />
      {isSearchOpen && <Search close={() => setIsSearchOpen(false)} lang={pageContext.data.lang} />}
      <Subscribe translations={_} />
      <NewHeader translations={_} languageCode={pageContext.data.lang} search={() => setIsSearchOpen(true)}/>
      <Footer translations={_} languageCode={pageContext.data.lang} />
    </Layout>
  )
}

export const query = graphql`
  query Post($langFile: String, $relatedPostsFilter: BlogPostFilterInput) {
    relatedPosts: allBlogPost(filter: $relatedPostsFilter, limit: 3) {
      nodes {
        id: postId
        categories {
          id
          name
          path
          slug
        }
        date
        excerpt
        image: imageMedium
        path
        readingTime
        title
      }
    }
    texts: file(relativePath: { eq: $langFile }) {
      _: childTextsYaml {
        ...HeaderTranslations
        ...FooterTranslations
        sports {
          basketball
          field_hockey
          floorball
          handball
          ice_hockey
          other_sports
          performance
          soccer
          tennis
          volleyball
        }
        chat {
          full_name
          email
          message
          send_message
          success_message
          success_info
          success
          message_placeholder
        }
        blog {
          blog_title
          all_title
          news_title
          features_title
          sports_title
          stories_title
          english
          czech
          min_reading
          read_more
          subscribe
          subscribe_newsletter
          email_placeholder
          xps_instagram
          follow_instagram
          related_articles
          subscribe_youtube
        }
      }
    }
  }
`

export default Post
