import React from "react"
import CategoryLabel from "./categoryLabel"
import styles from "./postLanding.module.scss"

const PostLanding = ({ title, date, image, categories, readingTime, translations }) => {
  return (
    <div
      className={styles.postLanding}
      style={{ backgroundImage: `url("${image}")` }}
    >
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <div className={styles.tags}>
            {categories.map((c) => (
              <CategoryLabel key={c.id} name={c.name} path={c.path} slug={c.slug} />
            ))}
          </div>
          <h1 dangerouslySetInnerHTML={{ __html: title }}></h1>
          <p className={styles.info}>
            {new Date(date).toLocaleDateString("en-US")}
            <span className={styles.dot}> •</span> {readingTime} {translations.blog.min_reading}
          </p>
        </div>
      </div>
    </div>
  )
}

export default PostLanding
